import React from 'react'
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

import Container from "@mui/material/Container";
import DialogSelect from './Minu';

function NavCourse() {
    return (
        <>
            <AppBar position="static" elevation={0} style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", backgroundColor: '#FFF' }}>
                <Container>
                    <Toolbar disableGutters>
                        <Box
                            sx={{
                                flexGrow: 1,
                                gap: 3,
                                display: { md: "flex" },
                            }}
                        >
                            <Box
                                style={{
                                    color: "#000",
                                    textDecoration: "none",
                                    fontSize: 15,
                                    fontWeight: "bold",
                                    border: "1px solid #FFF",
                                    borderRadius: "15px",
                                    padding: "10px"
                                }}
                            >
                                <DialogSelect />
                            </Box>

                        </Box>
                        <Box
                            sx={{
                                flexGrow: 1,
                                gap: 3,
                                display: { md: "flex" },
                            }}>
                            <Link
                                to="/"
                                style={{
                                    color: "#000",
                                    textDecoration: "none",
                                    fontSize: 15,
                                    fontWeight: "bold",
                                    letterSpacing: 1
                                }}>
                                Mr Eagle
                            </Link>
                        </Box>
                        <Link
                            to="/"
                            style={{
                                color: "inherit",
                                textDecoration: "none",
                            }}>
                            <img src="/mr-eagle 2.png" height={50} alt="logo"></img>
                        </Link>
                    </Toolbar>
                </Container>
            </AppBar>
        </>
    )
}

export default NavCourse