import { Box, Container, Grid, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import './Triller.css'
import ReactPlayer from 'react-player';
import screenfull from 'screenfull';
import ControlIcons from './ControlIcons';
import Btn from './global/Btn';
const format = (seconds) => {
    if (isNaN(seconds)) {
        return '00:00'
    }

    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = date.getUTCSeconds().toString().padStart(2, "0");

    if (hh) {
        return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`
    } else {
        return `${mm}:${ss}`
    }
};

function Triller() {
    const [playerstate, setPlayerState] = useState({
        playing: false,
        muted: false,
        volume: 0.5,
        playerbackRate: 1.0,
        played: 0,
        seeking: false,
    })


    //Destructure State in other to get the values in it
    const { playing, muted, volume, playerbackRate, played, seeking } = playerstate;
    const playerRef = useRef(null);
    const playerDivRef = useRef(null);

    //This function handles play and pause onchange button
    const handlePlayAndPause = () => {
        setPlayerState({ ...playerstate, playing: !playerstate.playing })
    }

    const handleMuting = () => {
        setPlayerState({ ...playerstate, muted: !playerstate.muted })
    }

    const handleRewind = () => {
        playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10)
    }

    const handleFastForward = () => {
        playerRef.current.seekTo(playerRef.current.getCurrentTime() + 30)
    }

    const handleVolumeChange = (e, newValue) => {
        setPlayerState({ ...playerstate, volume: parseFloat(newValue / 100), muted: newValue === 0 ? true : false, });
    }

    const handleVolumeSeek = (e, newValue) => {
        setPlayerState({ ...playerstate, volume: parseFloat(newValue / 100), muted: newValue === 0 ? true : false, });
    }

    const handlePlayerRate = (rate) => {
        setPlayerState({ ...playerstate, playerbackRate: rate });
    }

    const handleFullScreenMode = () => {
        screenfull.toggle(playerDivRef.current)
    }

    const handlePlayerProgress = (state) => {
        console.log('onProgress', state);
        if (!playerstate.seeking) {
            setPlayerState({ ...playerstate, ...state });
        }
        console.log('afterProgress', state);
    }

    const handlePlayerSeek = (e, newValue) => {
        setPlayerState({ ...playerstate, played: parseFloat(newValue / 100) });
        playerRef.current.seekTo(parseFloat(newValue / 100));
        // console.log(played)
    }

    const handlePlayerMouseSeekDown = (e) => {
        setPlayerState({ ...playerstate, seeking: true });
    }

    const handlePlayerMouseSeekUp = (e, newValue) => {
        setPlayerState({ ...playerstate, seeking: false });
        playerRef.current.seekTo(newValue / 100);
    }

    const currentPlayerTime = playerRef.current ? playerRef.current.getCurrentTime() : '00:00';
    const movieDuration = playerRef.current ? playerRef.current.getDuration() : '00:00';
    const playedTime = format(currentPlayerTime);
    const fullMovieTime = format(movieDuration);

    return (
        <div className='bgTriller' >
            <div className='playerDiv' ref={playerDivRef}></div>
            <Container>
                <Grid container spacing={4} sx={{ alignItems: 'center', justifyContent: 'center', padding: 0 }} >
                    <Grid item xs={12} md={5}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, }} >
                            <Typography variant="h2" sx={{ fontWeight: 'bold' }} >احصل على المهارات التي تحتاجها لوظيفة الفريلانس</Typography>
                            <Typography sx={{ fontSize: '20px' }}>انشر الوعي بعلامتك التجارية ، واكتشف الأنسب
                                منصة لمشروعك قم بتوسيع عميلك</Typography>
                            <Btn text={'سجل الأن'} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={7} sx={{ position: 'relative', padding: 0, mt: 2 }} style={{ padding: 0 }} >
                        <div className='playerDiv' ref={playerDivRef}>
                            <ReactPlayer width={'100%'} height='100%'
                                ref={playerRef}
                                url="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/TearsOfSteel.mp4"
                                playing={playing}
                                volume={volume}
                                playbackRate={playerbackRate}
                                onProgress={handlePlayerProgress}
                                muted={muted}
                                poster={'./../assets/logo/mr-eagle 2.png'}
                                light={'./../assets/logo/mr-eagle 2.png'}
                                // Disable download button
                                config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                            />
                            <ControlIcons
                                key={volume.toString()}
                                playandpause={handlePlayAndPause}
                                playing={playing}
                                rewind={handleRewind}
                                fastForward={handleFastForward}
                                muting={handleMuting}
                                muted={muted}
                                volumeChange={handleVolumeChange}
                                volumeSeek={handleVolumeSeek}
                                volume={volume}
                                playerbackRate={playerbackRate}
                                playRate={handlePlayerRate}
                                fullScreenMode={handleFullScreenMode}
                                played={played}
                                onSeek={handlePlayerSeek}
                                onSeekMouseUp={handlePlayerMouseSeekUp}
                                onSeekMouseDown={handlePlayerMouseSeekDown}
                                playedTime={playedTime}
                                fullMovieTime={fullMovieTime}
                                seeking={seeking}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default Triller