import React from 'react'
import Header from './../components/Header'
import Triller from '../components/Triller'
import AboutBro from '../components/AboutBro'
import HowMe from '../components/HowMe'
import Faq from '../components/Faq'
import FormLogic from '../components/FormLogic'
import { Button } from '@mui/material'
import Foteer from '../components/Foteer'
import NavBar from '../components/NavBar'

function Home() {
    return (
        <>
            <NavBar />
            <Header />
            <Triller />
            <AboutBro />
            <HowMe />
            <Faq />
            <FormLogic text={'انشئ حساب'} stateos={'1'} >
                <Button type='submit' sx={{
                    width: "150px",
                    borderRadius: "50px",
                    border: "1px solid #F7C744",
                    background: "#F7C744",
                    boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.40)",
                    margin: 'auto',
                    fontWeight: 'bold',
                    fontSize: '20px',
                    height: '50px'

                }} >{'$180'}</Button>
            </FormLogic>
            <Foteer />
        </>
    )
}

export default Home