import { Box, CardMedia, Container, Typography } from '@mui/material'
import React from 'react'
import man from './../assets/home/global/man.png'
import './HowMe.css'
function HowMe() {
    return (
        <Box className='bgHowMe' >
            <Container>
                <Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} >
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, textAlign: 'center' }} >
                            <Typography variant="h2" sx={{ fontWeight: 'bold' }}>من هو محمد صقر؟</Typography>
                            <CardMedia
                                component="img"
                                alt="green iguana"
                                image={man}
                                sx={{ borderRadius: '20px', width: '30%', margin: 'auto', height: '400px' }}
                            />
                            <Typography sx={{ fontSize: '20px' }}>انشر الوعي بعلامتك التجارية ، واكتشف أنسب منصة لمشروعك
                                قم بتوسيع قاعدة عملائك وجذب جمهورك المثالي من خلال وسائل التواصل الاجتماعي الرائدة
                                منصات الوسائط. تشمل خدماتنا</Typography>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default HowMe