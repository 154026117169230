import { Box, Container, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useForm } from "react-hook-form";
function FormLogic({ text, stateos, children }) {
    const { register, handleSubmit } = useForm();
    const [data, setData] = useState("");
    return (
        <Box p={4} >
            <Container>
                <Box>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'center', m: 2 }} className='topTitle' >
                        <div className="Ellipse32" style={{ width: '23px', height: '23px', background: '#F7C744', lineHeight: '23px', textAlign: 'center', borderRadius: 9999 }} >{stateos}</div>
                        <Typography sx={{ fontWeight: 'bold', textAlign: 'right', fontSize: '20px' }} >{text}</Typography>
                    </Box>
                    <form style={{ width: '100%' }} onSubmit={handleSubmit((data) => setData(JSON.stringify(data)))}>
                        <Box className='form' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 3, flexWrap: "wrap" }} >
                            <input style={{
                                width: '100%',
                                background: '#D9D9D9',
                                borderRadius: '20px',
                                height: '50px',
                                border: 'none',
                                padding: '10px',
                                fontSize: '16px',
                                fontWeight: 'bold'
                            }} {...register("email")} required={true} type='email' placeholder="البريد الألكتروني" className='email' />
                            <input style={{
                                margin: 'auto',
                                width: '48%',
                                background: '#D9D9D9',
                                borderRadius: '20px',
                                height: '50px',
                                border: 'none',
                                padding: '10px',
                                fontSize: '16px',
                                fontWeight: 'bold'
                            }} {...register("password")} required={true} type='password' placeholder="كلمة السر" className='password' />
                            <input style={{
                                margin: 'auto',
                                width: '48%',
                                background: '#D9D9D9',
                                borderRadius: '20px',
                                height: '50px',
                                border: 'none',
                                padding: '10px',
                                fontSize: '16px',
                                fontWeight: 'bold'
                            }} {...register("name")} required={true} type='text' placeholder="الأسم" className='name' />
                            <p>{data}</p>
                            {children}
                        </Box>
                    </form>
                </Box>
            </Container>
        </Box>
    )
}

export default FormLogic