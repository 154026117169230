import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

import Container from "@mui/material/Container";

function NavBar() {
    return (
        <>
            <AppBar position="static" elevation={0} style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", backgroundColor: '#F7C744' }}>
                <Container>
                    <Toolbar disableGutters>
                        <Box
                            sx={{
                                flexGrow: 1,
                                gap: 3,
                                display: { md: "flex" },
                            }}
                        >
                            <Link
                                to="/"
                                style={{
                                    color: "#000",
                                    textDecoration: "none",
                                    fontSize: 15,
                                    fontWeight: "bold",
                                    border: "1px solid #000",
                                    borderRadius: "15px",
                                    padding: "10px"
                                }}
                            >
                                تسجيل الخروج
                            </Link>

                        </Box>
                        <Box
                            sx={{
                                flexGrow: 1,
                                gap: 3,
                                display: { md: "flex" },
                            }}>
                            <Link
                                to="/"
                                style={{
                                    color: "#000",
                                    textDecoration: "none",
                                    fontSize: 15,
                                    fontWeight: "bold",
                                }}>
                                حسابي
                            </Link>
                        </Box>
                        <Link
                            to="/"
                            style={{
                                color: "inherit",
                                textDecoration: "none",
                            }}>
                            <img src="/mr-eagle 2.png" height={50} alt="logo"></img>
                        </Link>
                    </Toolbar>
                </Container>
            </AppBar>
        </>
    );
}
export default NavBar;
