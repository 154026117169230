import { Box, CardMedia, Container, Typography } from '@mui/material'
import React from 'react'
import CustomizedAccordions from './CustomizedAccordions'
import google from './../assets/home/icon/Google__G__Logo 1.svg'
import frcebook from './../assets/home/icon/facebook (2).svg'

function Faq() {
    return (
        <Box mt={4} >
            <Container>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', gap: 4 }} >
                    <Typography variant="h2" sx={{ fontWeight: 'bold' }}>أسئلة قد تدور في رأسك ..؟</Typography>
                    <CustomizedAccordions />
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        gap: 10,

                    }} >
                        <CardMedia
                            component="img"
                            alt="green iguana"
                            image={google}
                            sx={{ width: '100%', margin: 'auto', height: '50px' }}
                        />
                        <CardMedia
                            component="img"
                            alt="green iguana"
                            image={frcebook}
                            sx={{ width: '100%', margin: 'auto', height: '50px' }}
                        />
                    </Box>
                </Box>
            </Container>
        </Box >
    )
}

export default Faq