import { Box, CardMedia, Container, Typography } from '@mui/material'
import React from 'react'
import EggleLogo from './../assets/logo/mr-eagle 2.png'
import facebook from './../assets/home/icon/facebookF.svg'
import whatsApp from './../assets/home/icon/whatsApp (2).svg'
// import twetter from './../assets/home/icon/TwetterF.svg'
import './Foteer.css'
function Foteer() {
    return (
        <Box>
            <Container>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: 2 }} >
                    <CardMedia
                        component="img"
                        alt="green iguana"
                        height="150"
                        width="150"
                        image={EggleLogo}
                        sx={{ textAlign: 'center', objectFit: 'fill', width: '150px', margin: 'auto' }}
                    />
                    <Typography sx={{ fontWeight: 'bold', textAlign: 'right', fontSize: '20px' }} >Follow me</Typography>
                    <Box sx={{ display: 'flex', gap: 2 }} >
                        <div className="cercle"></div>
                        <CardMedia
                            component="img"
                            alt="green iguana"
                            // height="150"
                            width="150"
                            image={facebook}
                            sx={{ textAlign: 'center', objectFit: 'fill', width: '50px', margin: 'auto' }}
                        /><CardMedia
                            component="img"
                            alt="green iguana"
                            // height="150"
                            width="150"
                            image={whatsApp}
                            sx={{ textAlign: 'center', objectFit: 'fill', width: '50px', margin: 'auto' }}
                        />
                        {/* <CardMedia
                            component="img"
                            alt="green iguana"
                            height="150"
                            width="150"
                            image={twetter}
                            sx={{ textAlign: 'center', objectFit: 'fill', width: '50px', margin: 'auto' }}
                        /> */}
                        <div className="cercle"></div>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default Foteer