import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import { Typography } from '@mui/material';
export default function DialogSelect() {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
    };
    return (
        <div>
            <Button onClick={handleClickOpen}><MenuIcon /></Button>
            <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
                <DialogContent>
                    <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }}>
                        <FormControl sx={{ m: 1, minWidth: 240 }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, justifyContent: 'center', alignItems: 'center' }} >
                                <LogoutIcon sx={{ color: '#FF9800', fontSize: '20px', fontWeight: 'bold' }} />
                                <Typography>Logout</Typography>
                            </Box>
                        </FormControl>
                        <FormControl sx={{ m: 1, minWidth: 240 }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, justifyContent: 'center', alignItems: 'center' }} >
                                <PersonIcon sx={{ color: '#FF9800', fontSize: '20px', fontWeight: 'bold' }} />
                                <Typography>Account</Typography>
                            </Box>
                        </FormControl>
                        <FormControl sx={{ m: 1, minWidth: 240 }}>
                            <Button sx={{
                                width: "150px",
                                borderRadius: "50px",
                                border: "1px solid #F7C744",
                                background: "#F7C744",
                                boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.40)",
                                margin: 'auto',
                                fontWeight: 'bold',
                            }} >{'home'}</Button>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleClose}>Ok</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}