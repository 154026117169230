import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Typography } from '@mui/material';

export default function GutterlessList() {
    return (
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', direction: 'rtl', textAlign: 'right', margin: 'auto' }}>
            <ListItem
                disableGutters
                secondaryAction={
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 42 42" fill="none">
                        <circle cx="21" cy="21" r="21" fill="#F7C744" />
                    </svg>
                }
            >
                <ListItemText sx={{ textAlign: 'right' }}>
                    <Typography variant="h3" sx={{ fontWeight: 'bold' }}>ابدأ مشروعك عبر الإنترنت من خلال متجر
                        متجر إلكتروني متكامل</Typography>
                    <Typography sx={{ fontSize: '15px' }}>حول خدمة بناء المتجر عبر الإنترنت</Typography>
                </ListItemText>
            </ListItem>
            <ListItem
                disableGutters
                secondaryAction={
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 42 42" fill="none">
                        <circle cx="21" cy="21" r="21" fill="#F7C744" />
                    </svg>
                }
            >
                <ListItemText sx={{ textAlign: 'right' }}>
                    <Typography variant="h3" sx={{ fontWeight: 'bold' }}>ابدأ مشروعك عبر الإنترنت من خلال متجر
                        متجر إلكتروني متكامل</Typography>
                    <Typography sx={{ fontSize: '15px' }}>حول خدمة بناء المتجر عبر الإنترنت</Typography>
                </ListItemText>
            </ListItem>
            <ListItem
                disableGutters
                secondaryAction={
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 42 42" fill="none">
                        <circle cx="21" cy="21" r="21" fill="#F7C744" />
                    </svg>
                }
            >
                <ListItemText sx={{ textAlign: 'right' }}>
                    <Typography variant="h3" sx={{ fontWeight: 'bold' }}>ابدأ مشروعك عبر الإنترنت من خلال متجر
                        متجر إلكتروني متكامل</Typography>
                    <Typography sx={{ fontSize: '15px' }}>حول خدمة بناء المتجر عبر الإنترنت</Typography>
                </ListItemText>
            </ListItem>
            <ListItem
                disableGutters
                secondaryAction={
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 42 42" fill="none">
                        <circle cx="21" cy="21" r="21" fill="#F7C744" />
                    </svg>
                }
            >
                <ListItemText sx={{ textAlign: 'right' }}>
                    <Typography variant="h3" sx={{ fontWeight: 'bold' }}>ابدأ مشروعك عبر الإنترنت من خلال متجر
                        متجر إلكتروني متكامل</Typography>
                    <Typography sx={{ fontSize: '15px' }}>حول خدمة بناء المتجر عبر الإنترنت</Typography>
                </ListItemText>
            </ListItem>
        </List>
    );
}