import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import './CustomizedAccordions.css'
const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        sx={{ flexDirection: 'row-reverse' }}
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    direction: 'ltr',
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
    // eslint-disable-next-line no-dupe-keys
    '& .MuiAccordionSummary-content': {
        flexDirection: 'row-reverse'
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions() {
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <div style={{ width: '75%' }} >
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary sx={{ backgroundColor: '#D9D9D9' }} aria-controls="panel1d-content" id="panel1d-header">
                    <Typography sx={{ fontWeight: 'bold', textAlign: 'right' }} >

                        <div className="Ellipse32" style={{ width: '23px', height: '23px', background: '#F7C744', lineHeight: '23px', textAlign: 'center', borderRadius: 9999 }} >1</div>

                    </Typography>
                    <Typography sx={{ fontWeight: 'bold', textAlign: 'right', mr: 2, fontSize: '20px' }} >قد تكون الأسئلة تدور في رأسك</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ textAlign: 'right', fontSize: '24px', fontWeight: 'bold' }} >
                    <Typography sx={{ fontWeight: 'bold', fontSize: '18px' }} >
                        ابدأ مشروعك عبر الإنترنت من خلال خدمة متجر لبناء متجر إلكتروني متكامل ابدأ مشروعك
                        بناء متجر الكتروني متكامل
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary sx={{ backgroundColor: '#D9D9D9' }} aria-controls="panel2d-content" id="panel2d-header">
                    <Typography sx={{ fontWeight: 'bold', textAlign: 'right' }} >
                        <div className="Ellipse32" style={{ width: '23px', height: '23px', background: '#F7C744', lineHeight: '23px', textAlign: 'center', borderRadius: 9999 }} >2</div>
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold', textAlign: 'right', mr: 2, fontSize: '20px' }} >قد تكون الأسئلة تدور في رأسك</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ textAlign: 'right', fontSize: '24px', fontWeight: 'bold' }} >
                    <Typography sx={{ fontWeight: 'bold', fontSize: '18px' }} >
                        ابدأ مشروعك عبر الإنترنت من خلال خدمة متجر لبناء متجر إلكتروني متكامل ابدأ مشروعك
                        بناء متجر الكتروني متكامل
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary sx={{ backgroundColor: '#D9D9D9' }} aria-controls="panel3d-content" id="panel3d-header">
                    <Typography sx={{ fontWeight: 'bold', textAlign: 'right' }} >

                        <div className="Ellipse32" style={{ width: '23px', height: '23px', background: '#F7C744', lineHeight: '23px', textAlign: 'center', borderRadius: 9999 }} >3</div>

                    </Typography>
                    <Typography sx={{ fontWeight: 'bold', textAlign: 'right', mr: 2, fontSize: '20px' }} >قد تكون الأسئلة تدور في رأسك</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ textAlign: 'right', fontSize: '24px', fontWeight: 'bold' }} >
                    <Typography sx={{ fontWeight: 'bold', fontSize: '18px' }} >
                        ابدأ مشروعك عبر الإنترنت من خلال خدمة متجر لبناء متجر إلكتروني متكامل ابدأ مشروعك
                        بناء متجر الكتروني متكامل
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary sx={{ backgroundColor: '#D9D9D9' }} aria-controls="panel4d-content" id="panel3d-header">
                    <Typography sx={{ fontWeight: 'bold', textAlign: 'right' }} >

                        <div className="Ellipse32" style={{ width: '23px', height: '23px', background: '#F7C744', lineHeight: '23px', textAlign: 'center', borderRadius: 9999 }} >4</div>

                    </Typography>
                    <Typography sx={{ fontWeight: 'bold', textAlign: 'right', mr: 2, fontSize: '20px' }} >قد تكون الأسئلة تدور في رأسك</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ textAlign: 'right', fontSize: '24px', fontWeight: 'bold' }} >
                    <Typography sx={{ fontWeight: 'bold', fontSize: '18px' }} >
                        ابدأ مشروعك عبر الإنترنت من خلال خدمة متجر لبناء متجر إلكتروني متكامل ابدأ مشروعك
                        بناء متجر الكتروني متكامل
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
