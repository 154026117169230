import { Box, CardMedia, Grid, Typography } from '@mui/material'
import React from 'react'
import man from './../assets/home/global/man.png'
import './AboutBro.css'
import GutterlessList from './global/GutterlessList'
import Btn from './global/Btn';

function AboutBro() {
    return (
        <Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, textAlign: 'center' }} >
                <Typography sx={{ fontSize: '20px' }}>حول خدمة بناء المتجر عبر الإنترنت</Typography>
                <Typography variant="h2" sx={{ fontWeight: 'bold' }}>ابدأ مشروعك عبر الإنترنت من خلال متجر
                    متجر إلكتروني متكامل</Typography>
            </Box>
            <Box mt={6} >
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Box className={'bgAbout'} >
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '80%', height: '100px', alignItems: 'center' }} >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <circle cx="12" cy="12" r="12" transform="matrix(-1 0 0 1 24 0)" fill="black" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                        <circle cx="16" cy="16" r="16" transform="matrix(-1 0 0 1 32 0)" fill="#F7C744" />
                                    </svg>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }} >
                                    <CardMedia
                                        component="img"
                                        alt="green iguana"
                                        image={man}
                                        sx={{ borderRadius: '20px', width: '60%' }}
                                    />
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <circle cx="12" cy="12" r="12" transform="matrix(-1 0 0 1 24 0)" fill="black" />
                                    </svg>
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '30px',
                                    gap: 2,
                                    background: 'linear-gradient(168deg, #D9D9D9 0%, rgba(217, 217, 217, 0.10) 100%)',
                                    backdropFilter: 'blur(25px)',
                                    height: '75px',
                                    margin: '10px',
                                    padding: '5px',
                                    ml: '20%'

                                }} >
                                    <Typography sx={{ fontSize: '1.2rem' }} >7+
                                        Years experiences</Typography>
                                    <Typography sx={{ fontSize: '1.2rem' }} >|</Typography>
                                    <Typography sx={{ fontSize: '1.2rem' }} >150+
                                        Stores Built</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} mt={'100px'} textAlign={'center'}>
                        <GutterlessList />
                        <Btn text={'سجل الآن'} />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default AboutBro