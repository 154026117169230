import React from 'react'
import NavCourse from '../components/Course/NavCourse'
import { Box, Grid } from '@mui/material'
import Video from '../components/Course/Video'
import ListVideo from '../components/Course/ListVideo'

function Courses() {
    return (
        <>
            <NavCourse />
            <Grid container spacing={4} sx={{ alignItems: 'center', justifyContent: 'center', padding: 0 }} >
                <Grid item xs={12} md={6}>
                    <Box sx={{ position: 'relative', padding: 0, mt: 2 }} >
                        <Video />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box>
                        <ListVideo />
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default Courses