import { Button } from '@mui/material'
import React from 'react'

function Btn({ text }) {
    return (
        <Button sx={{
            width: "150px",
            borderRadius: "50px",
            border: "1px solid #F7C744",
            background: "#F7C744",
            boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.40)",
            margin: 'auto',
            fontWeight: 'bold',
            fontSize: '18px',
            height: '50px'

        }} >{text}</Button>
    )
}

export default Btn