import React from 'react'
import { Container, Box, CardMedia, Typography } from '@mui/material'
import EggleLogo from './../assets/logo/mr-eagle 2.png'
import head from './../assets/home/header/head.svg'
import insta from './../assets/home/icon/Frame (2).svg'
import tweeter from './../assets/home/icon/Group 23.svg'
import face from './../assets/home/icon/Group.svg'
function Header() {
    return (
        <Container>
            <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' }, gap: 4, margin: 'auto', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: { md: '55%', xs: '100%' }, position: 'relative' }}>
                    <Box sx={{ position: 'relative', display: 'flex', mt: 2 }} >
                        <CardMedia
                            component="img"
                            alt="green iguana"
                            height={100}
                            image={tweeter}
                            sx={{ textAlign: 'center', objectFit: 'fill', margin: 'auto', width: 'auto', position: 'absolute', left: '55%' }}
                        />
                        <CardMedia
                            component="img"
                            alt="green iguana"
                            height={100}
                            image={insta}
                            sx={{ textAlign: 'center', objectFit: 'fill', margin: 'auto', width: 'auto', position: 'absolute', left: '35%', top: '30px', }}
                        />
                        <CardMedia
                            component="img"
                            alt="green iguana"
                            height={100}
                            image={face}
                            sx={{ textAlign: 'center', objectFit: 'fill', margin: 'auto', position: 'absolute', left: '25%', top: '110px', width: 'auto', }}
                        />
                    </Box>
                    <Box display={'flex'} sx={{ justifyContent: 'space-between', position: 'relative' }} >
                        <div className="Ellipse15" style={{ width: 32, height: 32, background: '#F7C744', borderRadius: 9999, position: 'absolute' }} />
                        <div className="Ellipse15" style={{ width: 32, height: 32, background: 'black', borderRadius: 9999, position: 'absolute', left: '50px', top: '100px' }} />
                    </Box>

                    <CardMedia
                        component="img"
                        alt="green iguana"
                        height={550}
                        image={head}
                        sx={{ textAlign: 'center', objectFit: 'fill', mt: '35px' }}
                    />
                    <Box display={'flex'} sx={{ justifyContent: 'space-between', position: 'relative' }} >
                        <div className="Ellipse15" style={{ width: 32, height: 32, background: 'black', borderRadius: 9999, position: 'absolute', top: '-75px' }} />
                        <div className="Ellipse15" style={{ width: 32, height: 32, background: '#F7C744', borderRadius: 9999, position: 'absolute', left: '100px', top: '-150px' }} />
                    </Box>

                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, width: { md: '45%', xs: '100%' } }}>
                    <Box textAlign={'center'} >
                        <CardMedia
                            component="img"
                            alt="green iguana"
                            height="150"
                            width="150"
                            image={EggleLogo}
                            sx={{ textAlign: 'center', objectFit: 'fill', width: '150px', margin: 'auto' }}
                        /></Box>
                    <Typography variant="h1" sx={{ fontWeight: 'bold' }} >وظيفة أحلامك في انتظاركم</Typography>
                    <Typography>انشر الوعي بعلامتك التجارية ، واكتشف أنسب منصة لمشروعك
                        قم بتوسيع قاعدة عملائك وجذب جمهورك المثالي من خلال وسائل التواصل الاجتماعي الرائدة
                        منصات الوسائط. تشمل خدماتنا</Typography>
                </Box>

            </Box>
        </Container>
    )
}

export default Header